import { FC, Suspense } from "react";
import { EditCategoryItems } from "./EditCategoryItems";
import { Loading } from "../../Components/Loading/Loading";

import "./EditCategory.css";
import { Icon } from "../../Components/Icon/Icon";
import { useReactiveVar } from "@apollo/client";
import { selectedFiltersVar } from "../../App";

type Props = {
  /** The id of a category - note that this is also used for the headline */
  categoryId: string;
  /** The product number for a variant or the UUID of a group */
  selectedProductId: string;
};

export const EditCategory: FC<Props> = ({ categoryId, selectedProductId }) => {
  const selectedFilter = useReactiveVar(selectedFiltersVar);

  // Show the reset button if any filters are selected
  const showResetButton = !!selectedFilter.length;

  // Clear all filters on reset click
  function handleResetClick() {
    selectedFiltersVar([]);
  }

  // Template
  return (
    <div className="EditCategory">
      <div className="EditCategory__title">
        <div className="EditCategory__title-text">{categoryId}</div>

        {showResetButton && (
          <div className="EditCategory__title-reset" onClick={handleResetClick}>
            <div className="icon">
              <Icon name="resetNormal" width={20} />
            </div>
            <div className="text">Nulstil filtre</div>
          </div>
        )}
      </div>
      <div className="EditCategory__items">
        <Suspense fallback={<Loading />}>
          <EditCategoryItems
            category={categoryId}
            activeItemId={selectedProductId}
          />
        </Suspense>
      </div>
    </div>
  );
};
