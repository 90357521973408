import { useHistory } from "react-router-dom";
import "./Login.css";
import { LoginModal } from "../../Components/LoginModal/LoginModal";
import { config } from "../../config";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { useLoginMutation } from "../../Generated/graphql";
import { useState } from "react";

export const Login = () => {
  const history = useHistory();
  const [mutate] = useLoginMutation();
  const [state, setState] = useState<"idle" | "loading" | "error">("idle");

  const doLogin = async (username: string, password: string) => {
    setState("loading");

    const loginResult = await mutate({
      errorPolicy: "all",
      variables: {
        username,
        password,
      },
    });

    if (loginResult.errors) {
      // Display some kind of error in the form.
      console.error("Could not login with provided credentials");
      setState("error");
    }

    if (loginResult.data?.login) {
      // Navigate to main page
      history.push("/main");
    }
  };

  const error =
    state === "error" ? "Login mislykkedes. Prøv venligst igen. " : undefined;

  return (
    <div className="Login">
      {(state === "idle" || state === "error") && (
        <>
          <LoginModal
            logo={config.logo.appLogoName}
            logoWidth={config.logo.appLogoWidth}
            company={config.company.name}
            logIn={doLogin}
            error={error}
          />
        </>
      )}
      {state === "loading" && <LoadingComponent />}
    </div>
  );
};
