import { useEffect, useState } from "react";
import { useHistory, useRouteMatch, withRouter } from "react-router-dom";

import updateUser from "../../GraphQL/Mutations/updateUser";
import getUserById from "../../GraphQL/Query/getUserById";
import { FormElement } from "../../Components/FormElement/FormElement";
import { TextInput } from "../../Components/TextInput/TextInput";
import { Select, SelectOption } from "../../Components/Select/Select";
import { useMutation, useQuery } from "@apollo/client";
import { useMeQuery } from "../../Generated/graphql";
import {
  Breadcrumb,
  SettingsForm,
} from "../../Components/SettingsForm/SettingsForm";

const UserEdit = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const [updateUserMutation] = useMutation(updateUser);
  const query = useQuery(getUserById, {
    variables: { id: match.params.id },
  });

  const user = query.data?.getUserById ?? null;

  const { data: me } = useMeQuery();

  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(false);

  const onSave = () => {
    updateUserMutation({
      variables: {
        id: user.id,
        fullName: name,
        username: user.username,
        employeeNumber: user.employeeNumber,
        email: email,
        role: role,
      },
    }).then(() => {
      history.goBack();
    });
  };

  // Update state when user is loaded
  useEffect(() => {
    if (!user) {
      return;
    }

    setName(user.fullName);
    setRole(user.role);
    setEmail(user.email);
  }, [user]);

  if (query.loading) return <div>Loading...</div>;

  if (query.error) return <div>{query.error.message}</div>;

  const breadcrumbs: Breadcrumb[] = [
    {
      name: "Brugere",
      path: "/settings/users",
    },
    {
      name: user.fullName,
    },
  ];

  return (
    <SettingsForm onSave={onSave} breadcrumbs={breadcrumbs} theme="slim">
      <div className="UserEdit__fullname">
        <FormElement label="Navn">
          <TextInput
            value={name}
            change={(e: any) => {
              setName(e.target.value);
              setActive(true);
            }}
            blur={() => {}}
            keyPressed={() => {}}
            placeholder="Navn"
          />
        </FormElement>
      </div>
      <div>
        <FormElement label="Brugernavn">
          <TextInput value={user.username} readOnly />
        </FormElement>
      </div>
      <div className="UserEdit__email">
        <FormElement label="Email">
          <TextInput
            value={email}
            change={(e: any) => {
              setEmail(e.target.value);
              setActive(true);
            }}
            blur={() => {}}
            keyPressed={() => {}}
            placeholder="E-mail"
          />
        </FormElement>
      </div>

      {me?.me?.role === "ADMIN" && (
        <div className="UserEdit__role">
          <FormElement label="Rettigheder">
            <Select
              change={(v) => {
                setRole(v);
                !active && setActive(true);
              }}
              value={roles.find((x) => x.value === role)?.value || ""}
              type="default"
            >
              {roles.map(({ label, value }) => (
                <SelectOption key={value} value={value}>
                  {label}
                </SelectOption>
              ))}
            </Select>
          </FormElement>
        </div>
      )}
    </SettingsForm>
  );
};

const roles = [
  {
    label: "Administrator",
    value: "ADMIN",
  },
  {
    label: "Bruger",
    value: "USER",
  },
];

const routed = withRouter(UserEdit);

export { routed as UserEdit };
