import { FC } from "react";
import { Icon } from "../../Components/Icon/Icon";

type Props = {
  buttonText?: string;
  click: () => void;
};

export const DeleteButton: FC<Props> = ({ buttonText = "Slet", click }) => {
  return (
    <div className="Edit__actions-delete u-cursor-pointer" onClick={click}>
      <Icon name="deleteIcon" width={20} />
      <div className="Edit__actions-deleteText">{buttonText}</div>
    </div>
  );
};
