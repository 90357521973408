import { chain, isString } from "lodash";
import { Metadata } from "../Generated/graphql";
import {
  DataMissingFilter,
  PercentageCompleteFilter,
  ProductStatusFilter,
  ProductTypeFilter,
  SelectedFilters,
} from "../App";
import {
  QueryProduct,
  QueryProductVariant,
} from "../Containers/Overview/QueryProduct.type";
import { Percentage } from "./Percentage";
import { calculateGroupOrSinglePercentageDone } from "./calculateGroupPercentageDone";
import { calculateVariantPercentageDone } from "./calculateVariantPercentageDone";
import { isNonEmptyString } from "./String";
import { isNonEmptyArray } from "./Array";
import { getProductStatus } from "./getProductStatus";
import { never } from "./Type";

/**
 * Determine if a main product has any images
 */
const productGroupHasImages = (product: QueryProduct) => {
  return product.images.length > 0;
};

/**
 * Returns wether a product variant should be included in the set, given a specific ProductStatusFilter choice
 */
const productVariantHasStatus = (
  product: QueryProductVariant,
  filter: ProductStatusFilter
) => {
  const productStatus = getProductStatus(product);

  // If the product status could not be determined, we exclude them in the set as a default
  // This happens eg. with the group product which have no status
  if (!productStatus) {
    return false;
  }

  // If the product status code is in the filter, we include the product in the set
  return filter.value === productStatus.statusCode;
};

/**
 * Determine if a product is a Variant Group
 */
const isVariantGroup = (
  product: QueryProduct | QueryProductVariant
): product is QueryProduct => {
  // Only GroupProducts without a product number are VariantGroups
  return isMainProduct(product) && !product.productNumber;
};

/**
 * Determine if a product is a GroupProduct
 */
const isMainProduct = (
  product: QueryProduct | QueryProductVariant
): product is QueryProduct => {
  return product.__typename === "GroupProduct";
};

/**
 * Determine if a product is a GroupProductVariant
 */
const isVariant = (
  product: QueryProduct | QueryProductVariant
): product is QueryProductVariant => {
  return product.__typename === "GroupProductVariant";
};

/**
 * Determine if a variant has an image
 */
const productVariantHasImage = (product: QueryProductVariant) => {
  return isNonEmptyString(product.imageUrl);
};

/**
 * Returns true if the product is included in the filterede set, false if not
 *
 * Filters should be exclusive between types, but inclusive within types.
 * Eg. clicking a product status and missing images would show all products with the status AND images missing.
 * But clicking two "missing data" filters should include both types in the set, ie. all products missing both images OR specifications.
 * Therefore the need to run each filter type separately, and then combine the results.
 */
const applyProductFilters =
  (filters: SelectedFilters, metadata: Metadata[]) =>
  (product: QueryProduct | QueryProductVariant) => {
    // If no filter is chosen, show all products
    if (filters.length === 0) {
      return true;
    }

    // Get the filters for each type

    // Percentage complete
    const percentageCompleteFilters = filters.filter(
      (filter) => filter.type === "PERCENTAGE_COMPLETE"
    ) as PercentageCompleteFilter[];

    // Data missing
    const dataMissingFilters = filters.filter(
      (filter) => filter.type === "DATA_MISSING"
    ) as DataMissingFilter[];

    // Product status
    const productStatusFilters = filters.filter(
      (filter) => filter.type === "PRODUCT_STATUS"
    ) as ProductStatusFilter[];

    // Product type
    const productTypeFilters = filters.filter(
      (filter) => filter.type === "PRODUCT_TYPE"
    ) as ProductTypeFilter[];

    // We check for length on each of these, since we want to include all if no filter is chosen
    // Each of these represents within type OR results
    const includeByPercentageComplete =
      percentageCompleteFilters.length === 0
        ? true
        : percentageCompleteFilters.some((filter) =>
            applyPercentageCompleteFilter(filter, metadata, product)
          );

    const includeByDataMissing =
      dataMissingFilters.length === 0
        ? true
        : dataMissingFilters.some((filter) =>
            applyDataMissingFilter(filter, product)
          );

    const includeByProductStatus =
      productStatusFilters.length === 0
        ? true
        : productStatusFilters.some((filter) =>
            applyProductStatusFilter(filter, product)
          );

    const includeByProductType =
      productTypeFilters.length === 0
        ? true
        : productTypeFilters.some((filter) =>
            applyProductTypeFilter(filter, product)
          );

    // Return the AND of all the filters
    return (
      includeByPercentageComplete &&
      includeByDataMissing &&
      includeByProductStatus &&
      includeByProductType
    );

    // // Filter each product through the filters
    // // If any is a match, the product should be shown
    // return filters.some((filter) => {
    //   switch (filter.type) {
    //     // Percentage complete is not really used at this time, but kept if the customer wants it back
    //     case "PERCENTAGE_COMPLETE":
    //       return applyPercentageCompleteFilter(filter, metadata, product);
    //     case "DATA_MISSING":
    //       return applyDataMissingFilter(filter, product);
    //     case "PRODUCT_STATUS":
    //       return applyProductStatusFilter(filter, product);
    //     default:
    //       // If the filter is unknown, don't include the product
    //       // This would not happen IRL
    //       return false;
    //   }
    // });
  };

/**
 * Returns wether a product should be included in the set, given a specific ProductStatusFilter choice
 */
const applyProductStatusFilter = (
  filter: ProductStatusFilter,
  product: QueryProduct | QueryProductVariant
) => {
  // If the product is a group product, we return false
  // Essentially we skip filtering, since the group product has no status
  // The group will be preserved in the overall filter loop, based on the presence of variants after filtering
  if (isVariantGroup(product)) {
    return false;
  }

  // If the product is a variant, we can check the status directly
  return productVariantHasStatus(product, filter);
};

/**
 * Returns wether a product should be included in the set, given an specific DataMissingFilter choice
 */
const applyDataMissingFilter = (
  filter: DataMissingFilter,
  product: QueryProduct | QueryProductVariant
) => {
  // If the filter is not relevant for variants, we filter them out
  const valuesNotRelevantForVariants: DataMissingFilter["value"][] = [
    "LONG_DESCRIPTION",
    "SHORT_DESCRIPTION",
    "META_DESCRIPTION",
    "SPECIFICATIONS",
  ];

  if (
    isVariant(product) &&
    valuesNotRelevantForVariants.includes(filter.value)
  ) {
    return false;
  }

  switch (filter.value) {
    case "IMAGES":
      // The test is a bit different for group products and variants
      if (isMainProduct(product)) {
        return !productGroupHasImages(product);
      }
      if (isVariant(product)) {
        return !productVariantHasImage(product);
      }
      // This would never happen IRL, but we need a return case
      return false;
    // Check the simple fields by doing some basic tests
    // Remember this is kind of a reverse logic, since we want to show the products that are missing the data
    case "LONG_DESCRIPTION":
      // If the value is a non-empty string, the product should not be included
      return !chain(product)
        .get("customData.langbeskrivelse")
        .thru(isNonEmptyString)
        .value();
    case "SHORT_DESCRIPTION":
      // This should be a non-empty string
      return !chain(product)
        .get("customData.description")
        .thru(isNonEmptyString)
        .value();

    case "META_DESCRIPTION":
      // This should be a non-empty string
      return !chain(product)
        .get("customData.metaDescription")
        .thru(isNonEmptyString)
        .value();
    case "SPECIFICATIONS":
      // This should be a non-empty array
      return !chain(product)
        .get("customData.techSpecs")
        .thru(isNonEmptyArray)
        .value();
  }
};

/**
 * Returns wether a product should be included in the set, given a specific PercentageCompleteFilter choice
 * Note: There is some bug here, resulting in groups outside the range, with variants inside the range
 *       for some reason still get filtered away. I will not spend more time on this now, since the feature is not used
 */
const applyPercentageCompleteFilter = (
  filter: PercentageCompleteFilter,
  metadata: Metadata[],
  product: QueryProduct | QueryProductVariant
) => {
  // Get a range to check against, based on the selected filter
  const range =
    filter.value === 25
      ? [0, 25]
      : filter.value === 50
      ? [25, 50]
      : filter.value === 75
      ? [50, 75]
      : [75, 100];

  // Convert the range to percentages
  const startOfRange = Percentage.fromPercentage(range[0]);
  const endOfRange = Percentage.fromPercentage(range[1]);

  // Calculate the percentage done according to the rules for either variants or groups
  const percentageDone = isVariant(product)
    ? calculateVariantPercentageDone(metadata, product)
    : calculateGroupOrSinglePercentageDone(metadata, product);

  // Determine if the percentage done is within the range
  const productIsWithinRange = percentageDone.inRange(startOfRange, endOfRange);

  // The product should be included if it is within the range
  return productIsWithinRange;
};

/**
 * Filter for product types.
 * At the moment only VariantGroup is supported.
 */
const applyProductTypeFilter = (
  filter: ProductTypeFilter,
  product: QueryProduct | QueryProductVariant
) => {
  switch (filter.value) {
    case "VariantGroup":
      return isVariantGroup(product);
    default:
      never(filter.value);
  }
};

/** Filter Group Product type */
export const filterGroupProducts =
  (selectedFilters: SelectedFilters) =>
  ({
    search,
    metadata,
    products,
    category,
  }: {
    search: string;
    metadata: Metadata[];
    products: QueryProduct[];
    category?: string;
  }): QueryProduct[] => {
    let result: QueryProduct[] = products;

    /*
      Since we want the parents to be included if any of the variants are included,
      because we need the variant foldout in the UI to be accessible, 
      we first filter all the variants of each product, 
      then filter the parent product, but don't remove if there are variants that are included
    */

    result = result.flatMap((product) => {
      // Extract the variants, if any
      const variants = product.variants ?? [];

      // Filter the variants
      const filteredVariants = variants.filter(
        applyProductFilters(selectedFilters, metadata)
      );

      // Determine if there are any variants left, for later logic
      const hasVariantsLeft = filteredVariants.length > 0;

      // Determine if the main product is removed by the filters
      const isMainProductRemoved = !applyProductFilters(
        selectedFilters,
        metadata
      )(product);

      // If the main product is removed and there are no variants left, return an empty array
      // to remove the product from the list completely
      if (isMainProductRemoved && !hasVariantsLeft) {
        return [];
      }

      // Otherwise, return a new product with the filtered variants
      return {
        ...product,
        variants: filteredVariants,
      };
    });

    // Filter by category
    if (category) {
      result = result.filter((prod) => prod.category === category);
    }

    if (search) {
      result = result.filter((product) => {
        const isGroupMatch = isSearchMatch(search)({
          name: product.name,
          productNumber: product.productNumber,
        });

        const isVariantMatch =
          product.variants?.some(isSearchMatch(search)) ?? false;

        return isGroupMatch || isVariantMatch;
      });
    }

    return result;
  };

type SearchFields = {
  productNumber?: string | null;
  name: string;
};

const isSearchMatch = (str: string) => {
  const lowercaseString = str.toLowerCase();
  return (fields: SearchFields) => {
    return (
      [fields.name, fields.productNumber]
        // Filter out non-string values since productData values are not guaranteed to exist
        .filter(isString)
        // Check if the string contains the search string
        .some((x) => x.toLowerCase().includes(lowercaseString))
    );
  };
};
